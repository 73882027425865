import React, { useEffect, useCallback, useState, useRef } from "react";
import { Transition } from "@headlessui/react";

import { AppCta, Screen, ToggleMute } from "../Components";
import { useAudio } from "../Hooks";

import { IMAGES, SOUNDS, VIDEOS } from "../Config";

const Splash = ({ loading, handleLoaded }) => {
    const muteRef = useRef();
    const videoRef = useRef();
    const ctasRef = useRef();
    const { preload, play, stop, audioMap } = useAudio();
    const [introFinished, setIntroFinished] = useState(false);
    const [canLeave, setCanLeave] = useState(false);
    const [playSplash, setPlaySplash] = useState(false);

    useEffect(() => {
        if (audioMap && playSplash) {
            videoRef.current.play();

            play('SPLASH');

            setTimeout(() => {
                setCanLeave(true);
            }, 3500);
        }

    }, [audioMap, playSplash, videoRef]);

    useEffect(() => {
        if (introFinished && canLeave && !loading) {
            stop('SPLASH');

            handleLoaded();
        }
    }, [introFinished, canLeave, handleLoaded, loading]);

    const handleStart = useCallback((e) => {
        if (playSplash || muteRef.current.contains(e.target) || !videoRef?.current || ctasRef.current.contains(e.target)) {
            return;
        }

        setPlaySplash(true);

        preload(SOUNDS);
    }, [playSplash, muteRef, videoRef]);

    return (
        <div className="h-full cursor-pointer bg-black" onClick={handleStart}>
            <Screen className="flex justify-center align-center relative bg-black" padding={false}>
                <div className={`text-center w-full ${playSplash ? '' : 'opacity-0 pointer-events-none hidden'}`}>
                    <video
                        playsInline={true}
                        ref={videoRef}
                        webkit-playsinline="true"
                        className="w-full object-contain max-h-screen max-w-screen"
                        src={VIDEOS.SPLASH}
                        preload="auto"
                        muted
                        onEnded={() => setIntroFinished(true)}
                        onClick={() => setIntroFinished(true)}
                    />
                </div>

                <Transition
                    show={ !playSplash }
                    enter="transition-opacity duration-75"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-150"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                    className="absolute w-full h-full"
                >
                    <div className="absolute top-6 md:top-12 lg:top-24 left-6 md:left-10 z-10 text-white">
                        <ToggleMute
                            ref={muteRef}
                        />
                    </div>

                    <div className="text-4xl md:text-6xl text-white absolute inset-0 flex flex-col justify-center items-center cursor-pointer">
                        <img
                            src={ IMAGES.LOGO_WHITE }
                            className="w-full max-w-[10rem] mb-10"
                            alt="Lettuce"
                        />

                        TAP SCREEN TO PLAY
                    </div>

                    <AppCta
                        ref={ctasRef}
                    />
                </Transition>
            </Screen>
        </div>
    );
};

export default Splash;
