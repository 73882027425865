import React, { forwardRef } from "react";

const Screen = forwardRef(({ children, padding = true, className, verticalAlignment = "items-center" }, ref) => {
    return (
        <div
            ref={ref}
            className={ `mdw:aspect-9/16 flex flex-col mx-auto max-w-[600px] h-full ${padding ? 'md:py-12 lg:py-24 py-6 px-6 md:px-10' : ''} ${verticalAlignment} ${ className }` }
        >
            { children }
        </div>
    );
});

export default Screen;
