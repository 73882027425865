import axios from "axios";

const useAxios = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 5000,
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
    },
    validateStatus: () => true,
  });
};

export default useAxios;
