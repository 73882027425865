import {
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import Button from "./button";
import { useState } from "react";
import { Transition } from "@headlessui/react";

const Share = ({ url, description, onClick, className }) => {
    const [showShare, setShowShare] = useState(false);

    return (
        <>
            <Button
                onClick={ () => {
                    const data = { url, text: description };
                    if (
                        navigator &&
                        typeof navigator.canShare == "function" &&
                        navigator.canShare(data)
                    ) {
                        navigator.share(data);
                    } else {
                        setShowShare(!showShare);
                    }
                } }
                className="mb-4 w-1/2"
            >
                SHARE
            </Button>

            <Transition
                show={ showShare }
                enter="transition-all ease-in-out duration-500"
                enterFrom="opacity-0 -translate-y-6"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className={ `grid grid-cols-2 gap-2 mb-4 ${ className }` }>
                    {/* <EmailShareButton
        url={url}
        onClick={onClick}
        subject={"Initials"}
        body={description}
      >
        <EmailIcon round={true} className="w-[32px] h-[32px]" />
      </EmailShareButton> */ }

                    {/* <FacebookShareButton url={url} onClick={onClick} quote={description}>
        <FacebookIcon className="w-[32px] h-[32px]" round={true} />
      </FacebookShareButton> */ }

                    <TwitterShareButton url={ url } onClick={ onClick } title={ description }>
                        <TwitterIcon className="w-[32px] h-[32px]" round={ true }/>
                    </TwitterShareButton>

                    <WhatsappShareButton url={ url } onClick={ onClick } title={ description }>
                        <WhatsappIcon className="w-[32px] h-[32px]" round={ true }/>
                    </WhatsappShareButton>
                </div>
            </Transition>
        </>
    );
};

export default Share;
