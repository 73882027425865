import React from "react";
import Screen from "../Components/screen";

const Error = () => {
  return (
    <Screen>
      <div className="text-2xl">
        <p className="mb-4">Sorry, something went wrong.</p>
        <p>Please try again later.</p>
      </div>
    </Screen>
  );
};

export default Error;
