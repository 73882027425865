import React from "react";

import { Button, Share, Screen, ToggleMute, Era } from "../Components";

const Result = ({ handleToggleEnableStats, initial, setScreen, result }) => {
    const { success, hints, comparison } = result;

    return (
        <Screen className="justify-between relative">
            <div className="grid grid-cols-3 w-full mb-2">
                <div className="flex justify-start items-start">
                    <ToggleMute />
                </div>
                <div className="flex justify-center">
                    <Era
                        era={ initial?.era }
                    />
                </div>
                <div className="flex justify-end items-start">
                    <Button
                        chartIcon
                        onClick={handleToggleEnableStats}
                    />
                </div>
            </div>

            <div>
                <div className="text-center w-full">
                    {!success && (
                        <div className="md:text-4xl text-2xl mb-6">YOU'RE A SILLY LETTUCE</div>
                    )}

                    {success && comparison !== null && (
                        <div>
                            <div className="md:text-4xl text-xl mb-6">Fantastic you’re in the<br />  top {comparison}% of players</div>
                        </div>
                    )}

                    <div className="border border-black w-full border-2"></div>
                    { success && (
                        <>
                            <div className="md:text-8xl text-4xl mb-4 mt-6 text-center">
                                { initial?.song }
                            </div>
                            <div className="md:text-4xl text-2xl">BY { initial?.artist }</div>
                            <div className="md:text-2xl text-xl mt-4">COMPLETED WITH</div>
                            { hints === 0 && (
                                <div className="md:text-2xl text-xl mb-4 md:mb-8">0 CLUES</div>
                            ) }
                            { hints === 1 && (
                                <div className="md:text-2xl text-xl mb-4 md:mb-8">ONLY { hints } CLUE</div>
                            ) }
                            { hints > 1 && hints < 6 && (
                                <div className="md:text-2xl text-xl mb-4 md:mb-8">ONLY { hints } CLUES</div>
                            ) }
                            { hints === 6 && (
                                <div className="md:text-2xl text-xl mb-6"> { hints } CLUES</div>
                            ) }
                        </>
                    ) }
                    { !success && (
                        <>
                            <div className="md:text-8xl text-5xl mt-8 mb-4 text-center">
                                { initial?.song }
                            </div>
                            <div className="md:text-4xl text-2xl mb-8">BY { initial?.artist }</div>
                        </>
                    ) }
                    <div className="border border-black w-full border-2 mb-6" />
                </div>

                <div className="flex flex-col items-center">
                    <Share
                        url="https://www.playlettuce.com/"
                        description={ `Lettuce ${ initial.era }s. Day ${ initial.day }.\n${ success ? `${hints === 0 ? '👑' : new Array(hints).fill("💿").join("")}\n` : "" }` }
                        onClick={ () => {
                            setScreen("play-again");
                        } }
                    />

                    <Button
                        onClick={ () => setScreen("choose-era") }
                        className="text-base bg-transparent !text-black !pt-0"
                    >
                        OR PLAY AGAIN
                    </Button>
                </div>
            </div>

            <div className="text-green text-center text-xl">
                NEW SONG RELEASED DAILY
            </div>
        </Screen>
    );
};

export default Result;
