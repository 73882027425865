import React, { forwardRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons";

import { useAudio } from "../Hooks";

const MuteButton = forwardRef((props, ref) => {
    const { muted, toggleMute } = useAudio();

    return (
        <button
            ref={ ref }
            onClick={toggleMute}
        >
            <FontAwesomeIcon icon={muted ? faVolumeMute : faVolumeUp} size="lg" />
        </button>
    );
});

export default MuteButton;
