const useLocalStorage = () => {
  const has = (key) => {
    return localStorage.getItem(key) !== null;
  };

  const get = (key) => {
    return JSON.parse(localStorage.getItem(key));
  };

  const set = (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
  };

  const push = (key, value) => {
    try {
      const arr = get(key) || [];
      arr.push(value);
      set(key, arr);
      return arr;
    } catch (err) {
      console.error("failed to push value to key", key, err);
    }
  };

  const init = (key, value) => {
    if (!has(key)) {
      set(key, value);
    }
    return get(key);
  };

  const reset = () => {
    localStorage.clear();
  };

  return { get, set, has, init, push, reset };
};

export default useLocalStorage;
