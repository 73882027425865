import React, { useState } from "react";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Screen from "../Components/screen";
import { Button, ToggleMute } from "../Components";

import { IMAGES } from "../Config";

const HowTo = ({ showPlay, showClose, handlePlay, handleClose }) => {
    const [loaded, setLoaded] = useState(!showPlay);

    return (
        <div className="bg-center bg-cover bg-attached bg-app absolute inset-0 z-50">
            <Screen className="md:px-10 !px-2 relative">
                <div className="absolute top-6 md:top-12 lg:top-24 left-6 md:left-10">
                    <ToggleMute />
                </div>

                { showClose && (
                    <Button
                        xIcon
                        onClick={ handleClose }
                        className="absolute top-12 md:top-[7.5rem] right-6 md:right-10"
                    />
                ) }

                <div className={`md:text-2xl text-base flex justify-center flex-col items-center h-full ${loaded ? 'opacity-100' : ''} opacity-0 transition duration-200`}>
                    <h1
                        className={ `${
                            showClose ? "mt-8" : ""
                        } lg:pb-16 md:pb-8 pb-4 md:text-4xl text-2xl` }
                    >
                        HOW TO PLAY
                    </h1>

                    {!showPlay && (
                        <>
                            <p className="md:pb-4 pb-2">
                                GUESS <span className="md:text-3xl text-lg">THE SONG TITLE</span> FROM
                                THE INITIALS
                            </p>
                            <p className="">EACH WRONG GUESS WILL REVEAL A NEW CLUE</p>
                            <ul className="pt-8 text-left list-image-album pl-16 list-outside space-y-6">
                                <li>ARTIST INITIALS (SOLO OR GROUP)</li>
                                <li>YEAR & GENRE</li>
                                <li>BROKEN LYRICS</li>
                                <li>CRYPTIC CLUE</li>
                                <li>ANOTHER SONG BY THE SAME ARTIST</li>
                                <li>WORD REVEAL</li>
                            </ul>
                        </>
                    )}

                    {showPlay && (
                        <div className="w-full relative">
                            <img
                                src={IMAGES.HOW_TO_PLAY}
                                onLoad={() => setLoaded(true)}
                            />
                        </div>
                    )}

                    <p className="md:pb-4 pb-2 md:pt-12 pt-8 flex items-center">
                        PRESS <FontAwesomeIcon icon={ faCircleInfo } className="mx-1" /> FOR HELP
                    </p>
                    <p>TAP CLUE FOR DEFINITION</p>

                    { showPlay && (
                        <>
                            <Button onClick={ handlePlay } className="md:mt-16 mt-8">
                                LET'S PLAY
                            </Button>
                        </>
                    ) }
                </div>
            </Screen>
        </div>
    );
};

export default HowTo;
