import React, { useEffect, useState, createRef } from "react";
import stringSimilarity from "string-similarity";
import { Transition } from "@headlessui/react";
import Lottie from "lottie-react";

import { Initials, Era, Screen, Button, Hint, ToggleMute } from "../Components";
import { KEYS } from "../Config";
import { useApi, useLocalStorage, useAudio } from "../Hooks";
import { HowTo } from "../Screens";

import WinnerAnimation from "../Animations/winner-v7-green.json";

const SimilarityThreshold = 0.6;

const Play = ({ initial, deviceId, hints, setHints, setScreen, setResult, result }) => {
    const { success } = result || {};

    const { play, stop } = useAudio();
    const { loading, post } = useApi();
    const { push } = useLocalStorage();
    const inputRef = createRef();

    const [showPlayState, setShowPlayState] = useState(success === undefined);
    const [showAnimationState, setShowAnimationState] = useState(false);
    const [showResultsState, setShowResultsState] = useState(success !== undefined);
    const [isReplay] = useState(success !== undefined);
    const [canSubmit, setCanSubmit] = useState(false);
    const [song, setSong] = useState("");
    const [disabled, setDisabled] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showHowTo, setShowHowTo] = useState(false);
    const [showAnimation, setShowAnimation] = useState(true);
    const [showHints, setShowHints] = useState(0);
    const [endRevealInterval, setEndRevealInterval] = useState();
    const [hintAnimation, setHintAnimation] = useState(true);

    const handleInput = (event) => {
        setSong(event.target.value.toUpperCase());
    };

    const handleCheckAnswer = async (e) => {
        e.preventDefault();

        if (!song) {
            return;
        }

        const similarity = stringSimilarity.compareTwoStrings(
            song.toLowerCase(),
            initial?.song.toLowerCase()
        );

        const won = similarity > SimilarityThreshold;

        // Player hasn't won, show another hint
        if (!won) {
            play('RASPBERRY');
            handleHint();
        }

        const hintsReached = hints >= (initial?.hints.length || 0);

        // Check if player can try again
        if (!won && !hintsReached) {
            setHasError(true);

            setTimeout(() => {
                setHasError(false);
                setSong("");
            }, 500);

            return;
        }

        setDisabled(true);

        if (inputRef?.current) {
            inputRef?.current.blur();
        }

        if (won) {
            play('JACKPOT');

            handleAnimationEnd();
        } else {
            setShowResultsState(true);
            setShowPlayState(false);

            setEndRevealInterval(
                setInterval(() => {
                    setShowHints((showHint) => showHint + 1);
                }, 300)
            );
        }

        await commitResult(hints, deviceId, won);
    };

    const commitResult = async (hints, deviceId, success) => {
        // Game is finished
        const { data } = await post(`initials/${ initial.id }/plays`, {
            attempts: hints + 1,
            hints,
            time: 1,
            device_id: deviceId,
            success,
        });

        const result = {
            ...initial,
            hints,
            success,
            comparison: data?.comparison ?? null,
        };

        setResult(result);

        await push(KEYS.INITIALS_RESULTS, result);

        setCanSubmit(true);
    };

    const goToResults = () => setScreen("results");

    useEffect(() => {
        if (showHints > (initial?.hints.length || 0)) {
            clearInterval(endRevealInterval);
        }
    }, [showHints]);

    useEffect(() => {
        if (isReplay) {
            setEndRevealInterval(
                setInterval(() => {
                    setShowHints((showHint) => showHint + 1);
                }, 300)
            );
        }
    }, [isReplay]);

    useEffect(() => {
        if (isReplay) {
            setCanSubmit(true);
        }
    }, [loading, isReplay]);

    useEffect(() => {
        return () => {
            stop('JACKPOT');
        };
    }, []);

    useEffect(() => {
        if (showHints > 0 && hintAnimation) {
            setTimeout(() => setHintAnimation(false), 2000);
        }
    }, [showHints, setHintAnimation, hintAnimation]);

    const handleHint = () => {
        const maxHints = initial?.hints.length || 0;
        const value = Math.min(hints + 1, maxHints);
        setHints(value);
        setShowHints(value);
    };

    const handleShowHowTo = () => {
        setShowAnimation(false);
        setShowHowTo(true);
    };

    const handleCloseHowTo = () => {
        setShowHowTo(false);
    };

    const handleAnimationEnd = () => {
        setShowPlayState(false);

        setShowHints(0);

        setShowAnimationState(true);

        setTimeout(() => {
            setShowResultsState(true);

            setTimeout(() => {
                setEndRevealInterval(
                    setInterval(() => {
                        setShowHints((showHint) => showHint + 1);
                    }, 300)
                );
            }, 750);
        }, 1500);
    };

    const handleAnimationEnded = () => {
        setShowAnimationState(false);
    };

    const handleShowMeTheSong = async () => {
        await commitResult(hints, deviceId, false);

        goToResults();
    };

    return (
        <div className="relative h-full w-full">
            <Transition
                show={ showAnimationState }
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="h-full w-full max-w-[600px] absolute top-0 left-1/2 -translate-x-1/2"
            >
                <Screen
                    padding={false}
                >
                    <Lottie
                        className="w-full h-full object-contain max-h-screen max-w-screen"
                        animationData={WinnerAnimation}
                        loop={false}
                        onComplete={handleAnimationEnded}
                    />
                </Screen>
            </Transition>

            <Transition
                show={ !showAnimationState || showResultsState }
                enter="transition-opacity transform duration-1000"
                enterFrom="opacity-0 scale-25"
                enterTo="opacity-100 scale-100"
                leave="transition-opacity duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="h-full"
            >
                { !loading && showHowTo && (
                    <HowTo
                        showClose={ true }
                        handleClose={ handleCloseHowTo }
                    />
                ) }

                <Screen className="relative z-10 justify-between items-center">
                    <div className="grid grid-cols-3 w-full mb-2">
                        <div className="flex items-start justify-start">
                            <ToggleMute />
                        </div>
                        <div className="flex justify-center">
                            <Era
                                era={ initial?.era }
                            />
                        </div>
                        <div className="self-start justify-self-end">
                            { !success && (
                                <Button
                                    onClick={ handleShowHowTo }
                                    infoIcon
                                />
                            ) }
                        </div>
                    </div>

                    <Transition
                        show={ showResultsState }
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="text-5xl md:text-8xl text-center mb-4">
                            { initial?.song }
                        </div>

                        <div className="md:text-4xl text-2xl">BY { initial?.artist }</div>
                    </Transition>

                    <form
                        className="flex flex-col w-full items-center"
                        onSubmit={ handleCheckAnswer }
                    >
                        <Transition
                            className="flex flex-col w-full items-center px-4"
                            show={ showPlayState }
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Initials
                                initials={ initial?.song_initials }
                                showAnimation={ showAnimation }
                            />

                            <input
                                ref={ inputRef }
                                type="text"
                                className={ `w-full rounded-full text-center mb-4 border border-2 md:text-2xl text-xl py-1 focus:border-black uppercase ${
                                    hasError
                                        ? "bg-red border-red text-white animate__animated animate__headShake"
                                        : "bg-transparent border-black text-black"
                                }` }
                                placeholder="SONG NAME"
                                value={ song }
                                onChange={ handleInput }
                                enterKeyHint="go"
                                disabled={ disabled }
                                autoComplete="off"
                                autoCorrect="off"
                                autoCapitalize="off"
                            />

                            <Button
                                disabled={ !song || disabled }
                                type="submit"
                                sound={false}
                            >
                                ENTER ANSWER
                            </Button>
                        </Transition>

                        <div className="flex flex-col items-center md:gap-2 gap-1 mt-4">
                            { initial?.hints.map(({ id, hint }, idx) => (
                                <Hint
                                    key={ id }
                                    hint={ hint }
                                    enabled={ showHints > idx }
                                    requested={ (idx + 1) <= hints }
                                    enableHint={ () => handleHint(idx) }
                                    definition={ idx }
                                    ending={showResultsState}
                                />
                            )) }
                        </div>

                        <div className="relative">
                            {showPlayState && (
                                <>
                                    {hints < initial?.hints.length ? (
                                        <>
                                            <p className="text-base text-center md:mt-12 mt-8">
                                                TAP DISC FOR A CLUE
                                            </p>

                                            <p className={`text-sm text-center mt-1 transition duration-300 ${hints > 0 ? (hintAnimation ? 'text-green' : 'text-black opacity-50') : 'opacity-0'}`}>
                                                tap clue for definition
                                            </p>
                                        </>
                                    ) : (
                                        <Button
                                            type="button"
                                            onClick={ handleShowMeTheSong }
                                            className="md:mt-12 mt-8"
                                        >
                                            SHOW ME THE SONG
                                        </Button>
                                    )}
                                </>
                            )}
                        </div>

                        <Transition
                            show={ showResultsState }
                            enter="transition-opacity duration-75"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-150"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Button
                                type="button"
                                onClick={ goToResults }
                                className="md:mt-12 mt-8"
                                disabled={ !canSubmit }
                            >
                                NEXT
                            </Button>
                        </Transition>
                    </form>
                </Screen>
            </Transition>
        </div>
    );
};

export default Play;
