import React from "react";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons/faCircleXmark";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { faChartSimple } from "@fortawesome/sharp-solid-svg-icons/faChartSimple";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useAudio } from "../Hooks";

const Button = ({
    onClick,
    disabled,
    children,
    className,
    xIcon,
    infoIcon,
    chartIcon,
    sound = true,
    type = "submit",
}) => {
    const { play } = useAudio();

    const handleClick = async () => {
        if (sound) {
            play('BUBBLE');

            setTimeout(() => {
                if (onClick) {
                    onClick();
                }
            }, 200);
        } else {
            if (onClick) {
                onClick();
            }
        }
    };

    if (xIcon) {
        return (
            <button type={type} onClick={ handleClick } className={ className }>
                <FontAwesomeIcon icon={ faCircleXmark } size="lg" />
            </button>
        );
    }

    if (infoIcon) {
        return (
            <button type={type} onClick={ handleClick } className={ className }>
                <FontAwesomeIcon icon={ faCircleInfo } size="lg" />
            </button>
        );
    }

    if (chartIcon) {
        return (
            <button type={type} onClick={ handleClick } className={ className }>
                <FontAwesomeIcon icon={faChartSimple} size="lg" />
            </button>
        );
    }

    const classes = `bg-black text-white py-2 w-[250px] md:text-2xl text-xl ${
        disabled && "opacity-50"
    } ${ className }`;

    return (
        <button type={type} onClick={ handleClick } className={ classes } disabled={ disabled }>
            { children }
        </button>
    );
};
export default Button;
