import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import { Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/free-solid-svg-icons/faCaretLeft";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons/faCaretRight";
import { useSwipeable } from "react-swipeable";

import { useLocalStorage } from "../Hooks";

import { Screen, Button } from "../Components";

import { IMAGES, ERAS, KEYS } from "../Config";

const Stats = ({ handleToggleEnableStats, enabled }) => {
    const {  get } = useLocalStorage();
    const scaleContainerRef = useRef(null);

    const [loaded, setLoaded] = useState(false);
    const [eraIndex, setEraIndex] = useState(0);
    const [results, setResults] = useState([]);
    const [scale, setScale] = useState(1);

    const handlePreviousEra = () => {
        setEraIndex(ERAS[eraIndex - 1] !== undefined ? eraIndex - 1 : ERAS.length - 1);
    };

    const handleNextEra = () => {
        setEraIndex(ERAS[eraIndex + 1] !== undefined ? eraIndex + 1 : 0);
    };

    const handlers = useSwipeable({
        onSwipedLeft: handleNextEra,
        onSwipedRight: handlePreviousEra,
    });

    useEffect(() => {
        (async () => {
            try {
                setResults(await get(KEYS.INITIALS_RESULTS));
            } catch (error) {
                console.error("Error checking results storage:", error);
            }
        })();
    }, [enabled]);

    useEffect(() => {
        if (!scaleContainerRef?.current) {
            return;
        }

        const handleResize = () => {
            setScale(scaleContainerRef.current?.offsetWidth / 600);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [scaleContainerRef, enabled]);

    return (
        <div className={`absolute z-10 w-full h-full  ${enabled ? '' : 'pointer-events-none'}`}>
            <div
                className={`absolute inset-0 bg-app bg-center bg-cover bg-attached bg-app transition duration-500 ${enabled ? 'opacity-90' : 'opacity-0'}`}
            />

            <Screen
                className="absolute inset-0 z-50 justify-center text-[#e6ded7]"
                padding={false}
                ref={scaleContainerRef}
            >
                <Transition
                    show={ enabled }
                    enter="transition-opacity duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className={`relative ${loaded ? 'opacity-100' : ''} opacity-0 transition duration-200`}
                        {...handlers}
                    >
                        <>
                            <img
                                src={IMAGES.LOGO_LARGE}
                                onLoad={() => setLoaded(true)}
                            />

                            <Eras
                                scale={scale}
                                eraIndex={eraIndex}
                                handlePreviousEra={handlePreviousEra}
                                handleNextEra={handleNextEra}
                            />

                            <StatGrid
                                scale={scale}
                                era={ERAS[eraIndex]}
                                results={results}
                            />

                            <Graph
                                scale={scale}
                                era={ERAS[eraIndex]}
                                results={results}
                            />
                        </>
                    </div>

                    <div className={`w-full flex justify-center mt-6 ${loaded ? 'opacity-100' : ''} opacity-0 transition duration-200`}>
                        <Button onClick={handleToggleEnableStats}>
                            BACK TO GAME
                        </Button>
                    </div>
                </Transition>
            </Screen>
        </div>
    );
};

const Eras = ({ scale, eraIndex, handlePreviousEra, handleNextEra }) => {
    return (
        <div className="flex justify-center absolute top-[4%] z-20 w-full select-none">
            <div className="w-1/2 flex justify-around items-center">
                <div
                    className="cursor-pointer duration-200 hover:scale-125 transform transition"
                    onClick={handlePreviousEra}
                >
                    <FontAwesomeIcon
                        icon={faCaretLeft}
                        size="lg"
                    />
                </div>

                <div
                    className="w-1/3 text-center"
                    style={{
                        fontSize: `${scale * 2.25}rem`
                    }}
                >
                    {ERAS[eraIndex]}<small>s</small>
                </div>

                <div
                    className="cursor-pointer duration-200 hover:scale-125 transform transition"
                    onClick={handleNextEra}
                >
                    <FontAwesomeIcon
                        icon={faCaretRight}
                        size="lg"
                    />
                </div>
            </div>
        </div>
    );
};

const defaultStats = {
    plays: 0,
    winPercentage: 0,
    currentStreak: 0,
    maxStreak: 0,
};

const StatGrid = ({ scale, era, results }) => {
    const [stats, setStats] = useState(defaultStats);

    const statStyle = {
        fontSize: `${scale * 1.65}rem`,
    };

    const labelStyle = {
        fontSize: `${scale * 0.75}rem`,
    };

    useEffect(() => {
        if (!era && !results) {
            return;
        }

        try {
            const erasData = results?.filter(result => result.era === era && result.success);

            const plays = erasData.length;

            if (plays === 0) {
                setStats(defaultStats);
                return;
            }

            const wins = erasData.filter(result => result.won).length;
            const currentStreak = _.takeRightWhile(erasData, result => result.won).length;
            const maxStreak = _.max(_.map(_.groupBy(erasData, result => result.won), (results, won) => {
                return won === 'true' ? results.length : 0;
            }));

            setStats({
                plays,
                winPercentage: Math.round((wins / plays) * 100),
                currentStreak,
                maxStreak,
            });
        } catch (error) {
            console.error("Error calculating stats:", error);
            setStats(defaultStats);
        }
    }, [era, results]);

    return (
        <div className="absolute w-full top-[32%] flex justify-center">
            <div className="grid grid-cols-4 gap-2 w-1/2">
                <div className="flex flex-col items-center text-center space-y-0.5">
                    <div style={statStyle}>
                        { stats.plays }
                    </div>
                    <div style={labelStyle}>
                        Plays
                    </div>
                </div>

                <div className="flex flex-col items-center text-center space-y-0.5">
                    <div style={statStyle}>
                        { stats.winPercentage }
                    </div>
                    <div style={labelStyle}>
                        Win %
                    </div>
                </div>

                <div className="flex flex-col items-center text-center space-y-0.5">
                    <div style={statStyle}>
                        { stats.currentStreak }
                    </div>
                    <div style={labelStyle}>
                        Current Streak
                    </div>
                </div>

                <div className="flex flex-col items-center text-center space-y-0.5">
                    <div style={statStyle}>
                        { stats.maxStreak }
                    </div>
                    <div style={labelStyle}>
                        Max Streak
                    </div>
                </div>
            </div>
        </div>
    );
};

const defaultHintsGraph = [...new Array(7)].map((_, x) => ({
    label: x,
    value: 0,
    percent: 0,
}));

const Graph = ({ scale, era, results }) => {
    const [hintsGraph, setHintsGraph] = useState(defaultHintsGraph);

    useEffect(() => {
        if (!era && !results) {
            return;
        }

        try {
            const erasData = results?.filter(result => result.era === era && result.success);

            const hints = _.map(_.groupBy(erasData, result => result.hints), (results, hints) => ({
                hints,
                count: results.length,
            }));

            const totalHints = _.maxBy(hints, 'count')?.count ?? 0;

            if (totalHints === 0) {
                return setHintsGraph(defaultHintsGraph);
            }

            const hintsGraph = defaultHintsGraph?.map((item) => {
                const hintData = _.find(hints, h => Number(h.hints) === item.label);

                if (!hintData) {
                    return item;
                }

                return {
                    ...item,
                    value: hintData.count,
                    percent: Math.round((hintData.count / totalHints) * 100),
                };
            });

            setHintsGraph(hintsGraph);
        } catch (error) {
            console.error("Error calculating hints graph:", error);
            setHintsGraph(defaultHintsGraph);
        }
    }, [era, results]);

    return (
        <div className="absolute bottom-[12%] w-full flex items-center flex-col space-y-3 sm:space-y-4">
            <p style={{
                fontSize: `${scale * 2}rem`,
            }}>
                CLUES USED
            </p>

            <div className="flex space-x-3 w-3/5">
                {hintsGraph?.map((hint, index) => (
                    <GraphColumn
                        key={index}
                        scale={scale}
                        {...hint}
                    />
                ))}
            </div>
        </div>
    );
};

const GraphColumn = ({ scale, label, value, percent }) => {
    let textOffset = "";

    if (percent < 5) {
        textOffset = "-mt-8";
    } else if (percent < 10) {
        textOffset = "-mt-10";
    } else if (percent < 15) {
        textOffset = "-mt-12";
    }

    return (
        <div className="flex-1">
            <div
                className="w-full bg-[#1d1d1d] flex items-end"
                style={{
                    height: `${scale * 12}rem`,
                }}
            >
                <div
                    className="w-full bg-[#458600] text-white flex items-center justify-center transition-all duration-500"
                    style={{ height: `${percent}%` }}
                >
                    <div className={`transition-all text-xs sm:text-base duration-500 ${textOffset}`}>
                        {value}
                    </div>
                </div>
            </div>

            <div className="text-center mt-2" style={{
                fontSize: `${scale * 1.3}rem`
            }}>
                {label}
            </div>
        </div>
    );
};

export default Stats;
