import React, { useEffect, useState } from "react";
import { shuffle } from "lodash";

import { useAudio } from "../Hooks";

const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

const Digit = ({ value, offset, showAnimation, lengthOfAnimation }) => {
    const [letters] = useState([value, ...shuffle(alphabet.split(""))]);
    const [style, setStyle] = useState({});

    useEffect(() => {
        setStyle(showAnimation ? {
            animation: `${lengthOfAnimation}s ease-out ${(offset * 0.1).toFixed(1) + "s"} slide`,
        } : {});
    }, [showAnimation, offset]);

    return (
        <div className="digit lg:text-9xl md:text-7xl text-6xl lg:h-[130px] md:h-[74px] h-[60px] text-center">
            <div className="digit-content" style={ style }>
                { letters.map((i, key) => (
                    <div key={ key } className="digit-value">
                        { i }
                    </div>
                )) }
            </div>
        </div>
    );
};

const Odometer = ({ initials, showAnimation }) => {
    const { play } = useAudio();
    const lengthOfAnimation = 2.2 - (initials.length * 0.1);

    useEffect(() => {
        play("CASINO_WHEEL");
    }, []);

    return (
        <div className="odometer mb-4 md:mb-6 lg:mb-10">
            { initials.split("").map((digit, i) => (
                <Digit
                    key={ i }
                    value={ digit }
                    offset={ i }
                    showAnimation={ showAnimation }
                    lengthOfAnimation={ lengthOfAnimation }
                />
            )) }
        </div>
    );
};

export default Odometer;
