import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";

import content from "./content.md";

const Privacy = () => {
    const [markdownContent, setMarkdownContent] = useState(null);

    useEffect(() => {
        fetch(content).then((response) => response.text()).then((text) => setMarkdownContent(text));
    }, []);

    return (
        <div className="prose w-full mx-auto py-8 px-4 h-screen overflow-y-auto text-black">
            <Markdown>{markdownContent}</Markdown>
        </div>
    );
};

export default Privacy;
