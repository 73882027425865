import { useState } from "react";
import useAxios from "./useAxios";

const useApi = () => {
  const axiosApi = useAxios();
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);

  const get = async (url, params) => {
    setLoading(true);
    let response;
    const successStatus = 200;

    try {
      response = await axiosApi.get(url, { params });
    } catch (e) {
      console.error(e);
    }

    setData(response.data?.data ?? {});
    setError(response.status !== successStatus);
    setSuccess(response.status === successStatus);
    setLoading(false);

    return {
      data: response.data?.data ?? {},
      success: response.status === successStatus,
      error: response.status !== successStatus,
    };
  };

  const post = async (url, data) => {
    setLoading(true);
    let response;
    const successStatus = 201;

    try {
      response = await axiosApi.post(url, data).catch(console.error);
    } catch (e) {
      console.error(e);
    }

    setData(response.data?.data ?? {});
    setError(response.status !== successStatus);
    setSuccess(response.status === successStatus);
    setLoading(false);

    return {
      data: response.data?.data ?? {},
      success: response.status === successStatus,
      error: response.status !== successStatus,
    };
  };

  return {
    get,
    post,
    data,
    error,
    success,
    loading,
  };
};

export default useApi;
