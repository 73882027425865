/* global StatusBar */

import React, { useEffect } from "react";

import Home from "./Pages/Home";

const Cordova = () => {
    useEffect(() => {
        const initCordova = () => {
            if (typeof window.StatusBar !== undefined) {
                StatusBar.overlaysWebView(false);
                StatusBar.styleLightContent();
                // {dialog.object}.getPanelObject().resize();
            }
        };

        document.addEventListener("deviceready", initCordova, false);

        return () => {
            document.removeEventListener("deviceready", initCordova, false);
        }
    }, []);

    return (
        <div
            className={`text-center supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh] App w-screen font-anton bg-center bg-cover bg-attached bg-app uppercase tiny:overflow-hidden tiny:fixed overflow-auto`}
        >
            <Home />
        </div>
    );
};

export default Cordova;
