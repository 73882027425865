import React, { useState, createContext } from 'react';

const AudioContext = createContext({});

export const AudioProvider = ({ children }) => {
    const [audioMap, setAudioMap] = useState({});
    const [muted, setMuted] = useState(false);

    const toggleMute = () => {
        // Loop audioMap and toggle muted on all sounds.
        for (const [soundId, sound] of Object.entries(audioMap)) {
            if (!sound.paused) {
                sound.muted = !muted;
            }
        }

        setMuted(!muted);
    };

    const play = (soundId, loop = false) => {
        try {
            if (!muted && audioMap[soundId]) {
                const track = audioMap[soundId];

                // Prevent double play
                if (track.paused) {
                    track.pause();
                }

                track.currentTime = 0;
                track.loop = loop;
                track.play();
            } else {
                console.warn(`Sound ${ soundId } not found or audio is muted`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const stop = (soundId) => {
        try {
            if (audioMap[soundId]) {
                audioMap[soundId].pause();
                audioMap[soundId].currentTime = 0;
            } else {
                console.warn(`Sound ${soundId} not found or audio is muted`);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const preload = (sounds) => {
        const audioMapCopy = { ...audioMap };
        const promises = [];

        for (const [soundId, soundUrl] of Object.entries(sounds)) {
            const audio = new Audio(soundUrl);
            audio.muted = muted;
            audioMapCopy[soundId] = audio;

            const promise = new Promise((resolve, reject) => {
                audio.addEventListener("loadeddata", () => {
                    resolve();
                });

                audio.addEventListener("error", (error) => {
                    reject(error);
                });
            });

            promises.push(promise);
        }

        setAudioMap({ ...audioMapCopy });

        return Promise.all(promises);
    };

    return (
        <AudioContext.Provider value={{ toggleMute, play, audioMap, stop, preload, muted }}>
            {children}
        </AudioContext.Provider>
    );
};

export default AudioContext;
