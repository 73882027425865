import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";

import Home from "./Pages/Home";
import Privacy from "./Pages/Privacy";

import { config } from '@fortawesome/fontawesome-svg-core';

config.autoAddCss = false;

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/privacy",
        element: <Privacy />,
    },
]);

const App = ({ className = "supports-[height:100cqh]:h-[100cqh] supports-[height:100svh]:h-[100svh]" }) => {
    return (
        <div
            className={`text-center App ${className} w-screen font-anton bg-center bg-cover bg-attached bg-app uppercase tiny:overflow-hidden tiny:fixed overflow-auto`}
        >
            <RouterProvider router={router} />
        </div>
    );
};

export default App;
