import React from "react";
import Screen from "../Components/screen";
import { Button } from "../Components";

const PlayAgain = ({ setScreen }) => {
  return (
    <Screen className="justify-center">
      <div className="border border-black w-full border-2 mb-8"></div>

      <div className="text-9xl text-center">
          PLAY AGAIN
      </div>
      <div className="text-4xl mb-8">WITH A NEW DECADE</div>

      <div className="border border-black w-full border-2 mb-12"></div>

      <Button
        onClick={() => {
          setScreen("choose-era");
        }}
        className="mb-4 w-1/2"
      >
        PLAY AGAIN
      </Button>
    </Screen>
  );
};

export default PlayAgain;
