import React from "react";

const Era = ({ era }) => {
    return (
        <div className="bg-logo bg-cover w-[70px] h-[64px] md:w-[90px] md:h-[82px] flex pt-6 items-center justify-center">
            <div className="text-xl md:text-2xl lowercase text-light text-center">{ era }s</div>
        </div>
    );
};

export default Era;
