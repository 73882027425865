import React, { forwardRef} from "react";
import { isIOS, isAndroid } from "react-device-detect";

import { IMAGES } from "../Config";

const AppCta = forwardRef((props, ref) => {
    if (window.cordova) {
        return true;
    }

    const force = (!isIOS && !isAndroid);

    return (
        <div ref={ref} className="inset-x-0 bottom-10 absolute space-x-6 flex justify-center">
            {(isIOS || force) && (
                <a href="https://apps.apple.com/gb/app/play-lettuce/id6470848740" target="_blank" className="flex w-1/4">
                    <img src={IMAGES.APPLE_ICON} className="w-full filter-black" />
                </a>
            )}
            {(isAndroid || force) && (
                <a href="https://play.google.com/store/apps/details?id=com.playlettuce.app&pcampaignid=web_share" target="_blank" className="flex w-1/4">
                    <img src={IMAGES.ANDROID_ICON} className="w-full filter-black" />
                </a>
            )}
        </div>
    );
});

export default AppCta;
