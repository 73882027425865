import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Cordova from "./Cordova";

const renderReactDom = (cordova = false) => {
    const root = ReactDOM.createRoot(document.getElementById("root"));

    root.render(
        <React.StrictMode>
            {cordova ? <Cordova /> : <App />}
        </React.StrictMode>
    );
};

if (window.cordova) {
    document.addEventListener('deviceready', () => {
        renderReactDom(true);
    }, false);
} else {
    renderReactDom();
}
