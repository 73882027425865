import React, { useEffect, useState } from "react";
import Screen from "../Components/screen";
import Button from "../Components/button";
import { ERAS, KEYS } from "../Config";
import { useLocalStorage } from "../Hooks";
import { ToggleMute } from "../Components";

const ChooseEra = ({ initials, handlePlayGame }) => {
    const [enabledInitials, setEnabledInitials] = useState([]);
    const [activeInitial, setActiveInitial] = useState();
    const { get } = useLocalStorage();

    useEffect(() => {
        const storedResults = get(KEYS.INITIALS_RESULTS);
        setEnabledInitials(
            initials
                .filter((initial) => ERAS.includes(initial.era))
                .map((initial) => {
                    return {
                        ...initial,
                        played: !!storedResults.find((stored) => stored.id === initial.id),
                    };
                })
        );
    }, [initials]);

    const handlePlay = async () => handlePlayGame(activeInitial);

    const handleSelectEra = (index) => {
        setActiveInitial(enabledInitials[index]);
    };

    const hasPlayedAll = enabledInitials.every((initial) => initial.played);

    return (
        <Screen className="justify-center relative">
            <div className="absolute top-6 md:top-12 lg:top-24 left-6 md:left-10">
                <ToggleMute />
            </div>

            {!hasPlayedAll && (
                <div className="text-black  md:text-4xl text-2xl md:pb-8 pb-4">
                    CHOOSE DECADE
                </div>
            )}

            {hasPlayedAll && (
                <div className="text-black md:text-lg text-base md:pb-8 pb-4">
                    ALL DECADES COMPLETED<br />
                    COME BACK TOMORROW TO PLAY AGAIN
                </div>
            )}

            <div className="flex flex-col items-center w-full px-6 md:px-16">
                { enabledInitials.map((initial, idx) => (
                    <button
                        key={ initial.id }
                        onClick={ () => handleSelectEra(idx) }
                        className={ `w-full h-full lg:text-6xl md:text-5xl text-3xl py-2 md:py-3 ${
                            activeInitial?.id === initial.id
                                ? "bg-black text-white"
                                : "hover:bg-black hover:text-white hover:opacity-50"
                        } ${ initial.played && "opacity-25" }` }
                    >
                        { initial.era }s
                    </button>
                )) }
            </div>
            <Button
                onClick={ () => handlePlay() }
                disabled={ !activeInitial }
                className="md:mt-8 mt-4"
                sound={false}
            >
                PLAY
            </Button>
        </Screen>
    );
};

export default ChooseEra;
