import React, { useState, useEffect } from "react";

import { IMAGES } from "../Config";

import { useAudio } from "../Hooks";

const definitions = [
    'ARTIST INITIALS (SOLO OR GROUP)',
    'YEAR & GENRE',
    'BROKEN LYRICS',
    'CRYPTIC CLUE',
    'ANOTHER SONG BY THE SAME ARTIST',
    'WORD REVEAL',
];

const Hint = ({ hint, enabled, requested, enableHint, definition, ending }) => {
    const { play } = useAudio();

    const [manual, setManual] = useState(false);
    const [state, setState] = useState('hidden');
    const [showDefinition, setShowDefinition] = useState(!ending);

    useEffect(() => {
        if (enabled) {
            setState('showing');

            if (!ending && manual) {
                play('BUBBLE_POP');
                setManual(false);
            }

            setTimeout(() => setState('shown'), 500);
        }
    }, [enabled, requested]);

    useEffect(() => {
        if (enabled && !ending) {
            setTimeout(() => setShowDefinition(false), 2000);
        }
    }, [enabled]);

    const handleShowDefinition = () => {
        if (!requested && !ending) {
            return;
        }

        play('BUBBLE');

        setShowDefinition(!showDefinition);
    };

    const handleEnableHint = () => {
        setManual(true);

        enableHint();
    };

    return (
        <div className="relative">
            <div
                onClick={ handleShowDefinition }
                className={ `cursor-pointer transition duration-200 ${ state === 'shown' ? `${ !requested || showDefinition ? 'opacity-50' : 'opacity-100' }` : 'opacity-0' } md:text-3xl text-lg md:h-[40px] h-[30px] flex flex-row flex-nowrap whitespace-nowrap items-end justify-center hint-text` }
            >
                { showDefinition ? definitions[definition] : hint.split('___').map((word, index) => <span key={ index } className="mr-1">{ word }</span>) }
            </div>

            <button
                type="button"
                className={ `w-[30px] h-[30px] md:w-[40px] md:h-[40px] ring-0 outline-0 absolute z-10 transition-all ease-linear ${ state === 'showing' || state === 'shown' ? 'left-0' : 'left-1/2' } ${ state === 'shown' ? 'opacity-0 duration-200' : 'duration-500' } top-0 transform -translate-x-1/2` }
                onClick={ handleEnableHint }
            >
                <img
                    src={ IMAGES.ALBUM_COVER }
                    className={ `w-[30px] h-[30px] md:w-[40px] md:h-[40px] m-auto ${ state === 'showing' && 'animate-spin-reverse' }` }
                    alt="reveal hint"
                />
            </button>
        </div>
    );
};

export default Hint;
