import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
    Error,
    ChooseEra,
    Play,
    PlayAgain,
    Results,
    HowTo,
    Splash,
    Stats,
} from "../../Screens";
import { useApi, useLocalStorage } from "../../Hooks";
import { AudioProvider } from "../../Context/AudioProvider";
import { KEYS } from "../../Config";

const Home = () => {
    const { loading, data, success, error, get } = useApi();
    const { init: localInit, get: localGet } = useLocalStorage();

    const [hints, setHints] = useState(0);
    const [result, setResult] = useState(null);
    const [deviceId, setDeviceId] = useState("");
    const [initial, setInitial] = useState(null);
    const [screen, setScreen] = useState("splash");
    const [enableStats, setEnableStats] = useState(false);

    const loadPlayerId = () => {
        try {
            const storedId = localInit(KEYS.INITIALS_PLAYER_ID, uuidv4());
            setDeviceId(storedId);
        } catch (error) {
            console.error("Error loading deviceId:", error);
        }
    };

    const checkResultsStorageExists = () => {
        try {
            localInit(KEYS.INITIALS_RESULTS, []);
        } catch (error) {
            console.error("Error checking results storage:", error);
        }
    };

    useEffect(() => {
        (async () => {
            loadPlayerId();
            checkResultsStorageExists();

            await get("initials");
        })();
    }, []); // eslint-disable-line

    const handlePlayGame = async (initial) => {
        await setInitial(initial);

        // Check if results exist for this initial. Show the results screen if they do.
        try {
            const storedResults = localGet(KEYS.INITIALS_RESULTS);
            const storedResult = storedResults.find(
                (result) => result.id === initial.id
            );

            if (!!storedResult && !process.env.REACT_APP_ALLOW_MULTIPLE_ATTEMPTS) {
                console.log(1);
                setResult(storedResult);
                setHints(storedResult.hints);
                setScreen("play");
            } else {
                // Otherwise, set the initial and show the play screen
                setHints(0);
                setResult(null);
                setScreen("play");
            }
        } catch (error) {
            console.error("Error checking results:", error);
            setHints(0);
            setScreen("play");
        }
    };

    const handleToggleEnableStats = () => setEnableStats(!enableStats);

    return (
        <>
            <Stats
                enabled={ enableStats }
                handleToggleEnableStats={ handleToggleEnableStats }
            />

            {screen === "splash" && (
                <Splash
                    working={ loading }
                    handleLoaded={ () => setScreen("how-to") }
                />
            )}

            { (error || data?.length === 0) && <Error/> }

            { !loading && success && data?.length > 0 && (
                <>
                    { screen === "how-to" && (
                        <HowTo showPlay={ true } handlePlay={ () => setScreen("choose-era") }/>
                    ) }

                    { screen === "choose-era" && (
                        <ChooseEra
                            initials={ data }
                            deviceId={ deviceId }
                            handlePlayGame={ handlePlayGame }
                        />
                    ) }

                    { screen === "play" && (
                        <Play
                            initial={ initial }
                            hints={ hints }
                            setHints={ setHints }
                            setResult={ setResult }
                            result={ result }
                            initials={ data }
                            deviceId={ deviceId }
                            setScreen={ setScreen }
                        />
                    ) }

                    { screen === "results" && (
                        <Results
                            initial={ initial }
                            deviceId={ deviceId }
                            setScreen={ setScreen }
                            result={ result }
                            handleToggleEnableStats={handleToggleEnableStats}
                        />
                    ) }

                    { screen === "play-again" && (
                        <PlayAgain
                            initials={ data }
                            deviceId={ deviceId }
                            setScreen={ setScreen }
                        />
                    ) }
                </>
            ) }
        </>
    );
};

const HomeWithAudioProvider = (props) => (
    <AudioProvider>
        <Home {...props} />
    </AudioProvider>
);

export default HomeWithAudioProvider;
