import logo from "../Images/logo.png";
import logoSolid from "../Images/logo-solid.png";
import logoWhite from "../Images/logo-white.png";
import fullLogo from "../Images/full-logo.png";
import albumCover from "../Images/album-cover.png";
import albumCover20 from "../Images/album-cover-20x20.png";
import background from "../Images/background.png";
import logoLarge from "../Images/logo-large.png";
import howToPlay from "../Images/how-to-play.png";
import appleIcon from "../Images/apple-icon.png";
import androidIcon from "../Images/android-icon.png";

const IMAGES = {
    LOGO: logo,
    LOGO_SOLID: logoSolid,
    LOGO_WHITE: logoWhite,
    FULL_LOGO: fullLogo,
    LOGO_LARGE: logoLarge,
    ALBUM_COVER: albumCover,
    ALBUM_COVER_20: albumCover20,
    BACKGROUND: background,
    HOW_TO_PLAY: howToPlay,
    APPLE_ICON: appleIcon,
    ANDROID_ICON: androidIcon,
};

export default IMAGES;
