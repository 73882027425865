import bubble from "../Sounds/bubble.mp3";
import casinoWheel from "../Sounds/casino-wheel.mp3";
import bubblePop from "../Sounds/bubble-pop.mp3";
import jackpot from "../Sounds/jackpot.mp3";
import splash from "../Sounds/splash.mp3";
import raspberry from "../Sounds/raspberry.mp3";

const SOUNDS = {
    BUBBLE: bubble,
    CASINO_WHEEL: casinoWheel,
    BUBBLE_POP: bubblePop,
    JACKPOT: jackpot,
    SPLASH: splash,
    RASPBERRY: raspberry,
};

export default SOUNDS;
